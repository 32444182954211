export const ServiceUrl = {
	
	ADMISSION: {

		SETTINGS: 'v1/settings',
		LIST_VARIABLE_SETTINGS: 'v1/admin/settings/list-settings',
		UPDATE_VARIABLE_SETTINGS: 'v1/admin/settings/update-settings',
		ADD_VARIABLE_SETTINGS: 'v1/admin/settings/add-settings',
		
		USER_PROFILE: 'v1/admin/users/my-profile',
		USER_CHANGE_PASSWORD: 'v1/admin/users/change-password',
		
	},
	FIELD_SETTINGS: {
		NEW: 'v1/field_settings/new',
		LIST: 'v1/field_settings/all',
		REMOVE: 'v1/field_settings/delete',
		UPDATE: 'v1/field_settings/update',
	},
	SMS: {
		CHECK_BALANCE : 'v1/admin/sms/balance',
	},
	MANAGE: {
		
		SAVE_COURSE: 'v1/admin/manage/courses/save-data',
		LIST_COURSE: 'v1/admin/manage/courses/list-data',
		DELETE_COURSE: 'v1/admin/manage/courses/delete-data',
		UPDATE_COURSE: 'v1/admin/manage/courses/update-data',
		
		SAVE_CLASS: 'v1/admin/manage/classes/save-data',
		LIST_CLASS: 'v1/admin/manage/classes/list-data',
		DELETE_CLASS: 'v1/admin/manage/classes/delete-data',
		UPDATE_CLASS: 'v1/admin/manage/classes/update-data',

		LIST_PAYMENTMETHOD: 'v1/admin/manage/paymentmethod/list-data',
	},
	STUDENTS: {
		SAVE_STUDENT: 'v1/admin/students/save-data',
		LIST_STUDENTS: 'v1/admin/students/list-data',
		DELETE_STUDENT: 'v1/admin/students/delete-data',
		UPDATE_STUDENT: 'v1/admin/students/update-data',
	},
	MOCKTEST: {
		SAVE_TEST: 'v1/admin/mocktest/save-data',
		LIST_TESTS: 'v1/admin/mocktest/list-data',
		LIST_TEST_QUESTIONS: 'v1/admin/mocktest/list-test-questions',
		DELETE_TEST: 'v1/admin/mocktest/delete-data',
		UPDATE_TEST: 'v1/admin/mocktest/update-data',
	},
	DASHBOARD: {
		DASHBOARD_COUNT: 'v1/admin/dashboard/dashboard-count',
		BIRTHDAY_LIST: 'v1/admin/dashboard/birthday-list',
	},
};